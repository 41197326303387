<template>
  <div
    v-if="isOpen"
    class="modal-new-group swal2-container swal2-center swal-conection swal2-backdrop-show"
  >
    <div
      class="swal2-popup swal2-modal swal2-show"
      style="display: flex"
    >
      <div class="swal2-header position-relative">
        <div class="swal2-title">{{ $t('StructurePage.NewGroup') }}</div>
        <button
          type="button"
          class="swal2-close d-flex"
          aria-label="Close this dialog"
          @click="UPDATE_MODAL_NEW_GROUP_TOGGLE(false)"
        >
          ×
        </button>
      </div>
      <div class="swal2-content">
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Name')"
            label-for="name"
            :invalid-feedback="
              flagEqualName ? $t('DataFieldsPage.EqualGroupName') : $t('RequiredField')
            "
          >
            <b-form-input
              id="name"
              v-model="name"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.name"
              lazy-formatter
              :formatter="
                () => {
                  states.name = name !== '';
                  return name;
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Description')"
            label-for="description"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              :placeholder="$t('ResourceStatus.Placeholder')"
              no-resize
            />
          </b-form-group>
        </b-col>
      </div>
      <div class="swal2-actions">
        <button
          type="button"
          class="swal2-cancel btn btn-outline-danger ml-1 d-inline-block"
          @click="UPDATE_MODAL_NEW_GROUP_TOGGLE(false)"
        >
          {{ $t('IntegrationPage.cancel') }}
        </button>
        <button
          type="button"
          class="swal2-confirm btn btn-primary d-inline-block"
          @click="onSave()"
        >
          {{ $t('IntegrationPage.Save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  import { BFormGroup, BFormInput, BCol, BFormTextarea } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';

  export default {
    components: {
      BCol,
      BFormGroup,
      BFormInput,
      BFormTextarea
    },
    props: {
      entityId: {
        type: Number,
        default: null
      },
      groupList: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        isOpen: false,
        flagEqualName: false,
        name: '',
        description: '',
        states: {
          name: null
        }
      };
    },
    mounted() {
      this.UPDATE_MODAL_NEW_GROUP_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('dataFields', ['UPDATE_MODAL_NEW_GROUP_TOGGLE']),
      clearFields() {
        this.name = '';
        this.description = '';
      },
      clearValidations() {
        this.states.name = null;
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      fieldsValidate() {
        this.clearValidations();
        const validName = (this.states.name =
          this.name && this.flagEqualName === false ? true : false);

        return validName;
      },
      async onSave() {
        this.flagEqualName = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;
        const payload = {
          name: this.name,
          description: this.description,
          entityId: this.entityId
        };
        const alreadyHasName = this.groupList.find((e) => e.text === payload.name);
        await this.$http2
          .post('/api/myconfig/industrial-parameter/check-group-name', payload)
          .then((response) => {
            if (response.data.data.nameAlreadyExists || alreadyHasName) {
              this.flagEqualName = true;
              this.fieldsValidate();
            } else {
              this.UPDATE_MODAL_NEW_GROUP_TOGGLE(false);
              this.$emit('confirm', payload);
            }
          })
          .catch((error) => {
            this.showToast(this.$t('Error'), 'FrownIcon', e.message, 'danger');
          });
      }
    },
    computed: {
      ...mapState('dataFields', {
        isModalNewGroupOpen: 'isModalNewGroupOpen'
      })
    },
    watch: {
      isModalNewGroupOpen(v) {
        this.isOpen = v;
        if (!v) {
          this.clearValidations();
          this.clearFields();
        }
      }
    }
  };
</script>
<style lang="scss">
  .swal-conection.modal-new-group {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;

      .swal2-header {
        background-color: #eceff1;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #4c4541;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
          padding-bottom: 5px;
          padding-left: 2px;

          &:hover {
            color: #4c4541;
          }
        }
      }

      .swal2-content {
        padding: 20px 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        label {
          text-align: start;
        }

        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
          .invalid-feedback {
            text-align: start !important;
          }
        }

        p {
          text-align: start;
          margin-bottom: 16px;
        }

        .swal2-html-container {
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }

      .swal2-actions {
        padding: 0 16px 20px 16px;
        margin-top: 0;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }

          &:disabled {
            color: #7e7570 !important;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.213px;
            background: #ece0db !important;
            border-color: #ece0db !important;
          }
        }

        .swal2-cancel {
          order: 1;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }
</style>
