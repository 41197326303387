<template>
  <div class="d-flex">
    <b-sidebar
      id="group-sidebar-right"
      class="sidebar-add-group"
      right
      bg-variant="white"
      :width="device === 'desktop' ? '407px' : device === 'tablet' ? '50vw' : '100vw'"
      v-model="isOpen"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ `${$t(modalTitle)} ${$t('StructurePage.Group')}` }}
          </span>
          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>
        <div class="separator"></div>
      </template>
      <template>
        <div>
          <b-col md="12">
            <b-form-group
              :label="$t('DepartmentPage.Name')"
              label-for="name"
              :invalid-feedback="
                flagEqualName ? $t('DataFieldsPage.EqualGroupName') : $t('RequiredField')
              "
            >
              <b-form-input
                id="name"
                v-model="name"
                :placeholder="$t('ResourceStatus.Placeholder')"
                :state="states.name"
                lazy-formatter
                :formatter="
                  () => {
                    states.name = name !== '';
                    return name;
                  }
                "
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              :label="$t('DepartmentPage.Description')"
              label-for="description"
              :invalid-feedback="$t('RequiredField')"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                :placeholder="$t('ResourceStatus.Placeholder')"
                no-resize
              />
            </b-form-group>
          </b-col>
        </div>
      </template>

      <template #footer>
        <div class="separator"></div>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div :class="['d-flex', 'buttons']">
            <b-button
              :class="['btn-cancel', 'btn-footer']"
              @click="closeModal"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="onSave()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormGroup, BFormInput, BFormTextarea, BButton, BCol, BSidebar } from 'bootstrap-vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

  export default {
    data() {
      return {
        isOpen: false,
        flagEqualName: false,
        prefix: localStorage.getItem('prefix'),
        name: '',
        description: '',
        states: {
          name: null
        }
      };
    },
    components: {
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BButton,
      BCol,
      BSidebar,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg')
    },
    props: {
      modalTitle: {
        type: String,
        default: ''
      },
      currentId: {
        type: Number,
        default: null
      },
      device: {
        type: String,
        default: 'desktop'
      },
      entityId: {
        type: Number,
        default: null
      }
    },
    mounted() {
      this.UPDATE_MODAL_ADD_GROUP_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('dataFields', ['UPDATE_MODAL_ADD_GROUP_TOGGLE']),
      closeModal() {
        this.UPDATE_MODAL_ADD_GROUP_TOGGLE(false);
      },
      removeItem(index) {
        this.optionsList.splice(index, 1);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      clearFields() {
        this.name = '';
        this.description = '';
      },
      clearValidations() {
        this.states.name = null;
      },
      fieldsValidate() {
        this.clearValidations();
        const validName = (this.states.name =
          this.name && this.flagEqualName === false ? true : false);

        return validName;
      },
      async getInfos() {
        if (this.modalTitle.includes('Edit')) {
          this.UPDATE_FLAG_SHOW_OVERLAY(true);
          await this.$http2
            .get(`/api/myconfig/industrial-parameter/group/${this.currentId}/details`)
            .then((response) => {
              this.name = response.data.data.name;
              this.description = response.data.data.description;
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      },
      async onSave() {
        this.flagEqualName = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        if (this.modalTitle.includes('Edit')) {
          const payload = {
            id: this.currentId,
            name: this.name,
            description: this.description
          };
          await this.$http2
            .put(`/api/myconfig/industrial-parameter/group/${this.currentId}`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('DataFieldsPage.GroupEdited'),
                'success'
              );
              this.$emit('updateList');
              this.closeModal();
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.id === 94) {
                  this.flagEqualName = true;
                } else {
                  this.showToast(this.$t('Error'), 'FrownIcon', e.message, 'danger');
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        } else {
          const payload = {
            name: this.name,
            description: this.description,
            entityId: this.entityId
          };
          await this.$http2
            .post('/api/myconfig/industrial-parameter/group', payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('DataFieldsPage.GroupCreated'),
                'success'
              );
              this.$emit('updateList');
              this.closeModal();
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.id === 94) {
                  this.flagEqualName = true;
                } else {
                  this.showToast(this.$t('Error'), 'FrownIcon', e.message, 'danger');
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      }
    },
    computed: {
      ...mapState('dataFields', {
        isModalAddGroupOpen: 'isModalAddGroupOpen'
      })
    },
    watch: {
      isModalAddGroupOpen(v) {
        this.isOpen = v;
        this.clearFields();
        this.clearValidations();
        if (v) {
          this.getInfos();
        }
      }
    }
  };
</script>

<style lang="scss">
  .sidebar-add-group {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        width: 100%;
        min-height: 60px;
        padding: 16px;

        .arrow-left-icon {
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-right: 8px;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;

          #info-option {
            margin-left: 8px;
            cursor: pointer;
          }
        }

        .close-icon {
          border: 1px solid #974900;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
      transition: all 0.5s ease-out;
      height: 100% !important;

      .b-sidebar-body {
        padding-block: 16px;
        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
