<template>
  <div id="data-fields-page">
    <modal-delete
      :name="currentName"
      :type="currentType"
      :exhibitionLocation="currentExhibitionLocation"
      :exhibitionList="exhibitionLocation"
      @confirm="deleteItems"
    />
    <modal-new-field
      :modalTitle="modalTitle"
      :device="device"
      :entityId="selectedEntity"
      :groupList="groupList"
      :exhibitionLocation="exhibitionLocation"
      :formatList="industrialStructParameterTypes"
      :currentId="currentId"
      :appointmentId="appointmentId"
      @updateList="getDataFields"
    />
    <modal-new-group
      :modalTitle="modalTitle"
      :device="device"
      :entityId="selectedEntity"
      :currentId="currentId"
      @updateList="getDataFields"
    />
    <div class="header-block">
      <div class="title-page">
        {{ $t('Breadcrumbs.DataFields') }}
      </div>
      <scrollable-arrows>
        <div class="entities">
          <div
            v-for="entity in industrialEntities"
            :key="entity.id"
            :class="['entity', selectedEntity === entity.id ? '--current-entity' : '']"
            @click="selectedEntity = entity.id"
          >
            {{ `${subtitlePlural(entity)} (${entity.quantity})` }}
          </div>
        </div>
      </scrollable-arrows>

      <div
        class="search-input col-12"
        v-if="!(dragList.length === 0 && !noItemsFound)"
      >
        <b-col
          cols="12"
          sm="8"
          md="8"
          lg="9"
          xl="9"
          class="div-custom-input input__fields"
        >
          <div class="icon-block">
            <SearchIcon class="search-icon" />
          </div>
          <b-form-tags
            class="input-search-fields"
            ref="formTagFields"
            v-model="filter.textFilter"
            duplicate-tag-text=""
            :placeholder="filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''"
            @input="getDataFields()"
            remove-on-delete
            add-on-change
          />
        </b-col>
        <b-col
          cols="12"
          sm="4"
          md="4"
          lg="3"
          xl="3"
          class="pr-1 pr-sm-0 add-field"
        >
          <b-button
            class="btn-new"
            @click="changeModalName()"
          >
            <AddIcon class="add-icon" />
            {{ $t('DataFieldsPage.NewField') }}
          </b-button>
          <b-button
            id="new-group"
            class="icon-option"
            ><ArrowDown class="dropdown"
          /></b-button>
          <Popover
            target="new-group"
            :items="popoverList"
            placement="bottom"
            customPosition="w-100"
            triggers="focus"
          />
        </b-col>
      </div>
    </div>
    <div
      class="block-no-items"
      v-if="dragList.length === 0 && !noItemsFound"
    >
      <div class="no-items h-100">
        <EmptyBoxIcon class="empty-box" />
        <p class="title">{{ $t('DataFieldsPage.NoDataTitle') }}</p>
        <p class="subtitle">
          {{ $t('DataFieldsPage.NoDataSubtitle') }}
        </p>
        <b-button
          class="btn-new"
          @click="changeModalName()"
        >
          <AddIcon class="add-icon" />
          {{ $t('DataFieldsPage.NewField') }}
        </b-button>
      </div>
    </div>
    <div
      v-else
      class="body-block"
    >
      <CollapseDraggable
        isDataFields
        :list="dragList"
        :dragList="dragList"
        :dragListStart="dragListStart"
        :optionIcons="optionIcons"
        :levelIcons="levelIcons"
        :selectedChecks="selectedChecks"
        :shouldUpdate="shouldUpdate"
        :forceRender="forceRender"
        :exhibitionLocationList="exhibitionLocation"
        @selectedToZero="UPDATE_SELECTED_CHECKS(0)"
        @deleteItems="(ids) => deleteItems(ids)"
        @updatedList="updatedList"
        @getEditInfos="(id, level, type) => getEditInfos(id, level, type)"
        @checkedCounter="checkedCounter"
        @undoChangesTree="undoChangesTree"
        @saveActualTree="saveActualTree"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormTags, BButton, BCol } from 'bootstrap-vue';
  import ModalNewField from './components/ModalAddEditField.vue';
  import ModalNewGroup from './components/ModalAddEditGroup.vue';
  import ModalDelete from './components/ModalDelete.vue';
  import Popover from '@/@core/layouts/layout-horizontal/components/Menu/Popover.vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import SearchIcon from '@core/assets/icons/search-icon.svg';
  import AddIcon from '@core/assets/icons/add-icon.svg';
  import ArrowDown from '@core/assets/icons/arrow-down.svg';
  import GroupIcon from '@core/assets/icons/department-icon.svg';
  import DataFieldIcon from '@core/assets/icons/data-field-icon.svg';
  import EmptyBoxIcon from '@core/assets/icons/empty-box-icon.svg';
  import CollapseDraggable from '@/@core/components/collapse-draggable/CollapseDraggable.vue';
  import InfoIcon from '@core/assets/icons/info-outline-icon.svg';
  import OptionsIcon from '@core/assets/icons/options-icon.svg';
  import EditIcon from '@core/assets/icons/edit-icon.svg';
  import DeleteIcon from '@core/assets/icons/delete-icon.svg';
  import ScrollableArrows from '@core/components/scrollable-arrows/index.vue';

  export default {
    components: {
      BFormTags,
      BButton,
      BCol,
      ToastificationContent,
      Popover,
      ModalNewField,
      CollapseDraggable,
      ModalNewGroup,
      ModalDelete,
      GroupIcon,
      SearchIcon,
      AddIcon,
      InfoIcon,
      DeleteIcon,
      EditIcon,
      OptionsIcon,
      ArrowDown,
      EmptyBoxIcon,
      ScrollableArrows
    },
    data() {
      return {
        filter: {
          textFilter: []
        },
        modalTitle: '',
        noItemsFound: false,
        currentId: null,
        currentName: null,
        currentType: null,
        currentExhibitionLocation: [],
        firstContextLoading: true,
        forceRender: 0,
        shouldUpdate: false,
        industrialEntities: [],
        exhibitionLocation: [],
        groupList: [],
        industrialStructParameterTypes: [],
        selectedEntity: null,
        appointmentId: null,
        popoverList: [
          {
            title: 'StructurePage.NewGroup',
            click: () => {
              this.modalTitle = 'DepartmentPage.New';
              this.UPDATE_MODAL_ADD_GROUP_TOGGLE(true);
            }
          }
        ],
        prefix: localStorage.getItem('prefix'),
        levelIcons: [
          {
            title: 'StructurePage.Group',
            techName: 'Group',
            level: 1,
            icon: GroupIcon
          },
          {
            title: 'Breadcrumbs.DataFields',
            techName: 'DataField',
            level: 2,
            icon: null
          },
          {
            title: '',
            techName: '',
            level: 3,
            icon: null
          }
        ],
        optionIcons: [
          {
            title: 'Breadcrumbs.DataFields',
            icon: DataFieldIcon,
            click: () => {}
          },
          {
            title: 'Info',
            icon: InfoIcon,
            click: () => {}
          },
          {
            title: 'DepartmentPage.Actions',
            icon: OptionsIcon,
            click: () => {},
            actions: [
              {
                title: 'DepartmentPage.Edit',
                icon: EditIcon,
                click: () => {
                  this.modalTitle = 'DepartmentPage.Edit';
                  if (this.currentType === 'field') {
                    this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(true);
                  }
                  if (this.currentType === 'group') {
                    this.UPDATE_MODAL_ADD_GROUP_TOGGLE(true);
                  }
                }
              },
              {
                title: 'DepartmentPage.Delete',
                icon: DeleteIcon,
                click: () => {
                  this.currentName = this.getCurrentObject(this.dragList).name;
                  this.currentExhibitionLocation = this.getCurrentObject(
                    this.dragList
                  ).exhibitionLocation;
                  this.UPDATE_MODAL_DELETE(true);
                }
              }
            ]
          }
        ],
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile'
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
      this.getDataFields();
      this.activeCarousel();
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('departments', ['UPDATE_MODAL_DELETE_SNACK_BAR']),
      ...mapMutations('dataFields', [
        'UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE',
        'UPDATE_MODAL_ADD_GROUP_TOGGLE',
        'UPDATE_SELECTED_CHECKS',
        'UPDATE_DRAG_LIST',
        'UPDATE_DRAG_LIST_START',
        'UPDATE_MODAL_DELETE'
      ]),
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      activeCarousel() {
        const vm = this;
        $(function () {
          document.getElementsByClassName('input-search-fields')[0].setAttribute('tabIndex', '-1');

          const slider = document.querySelector('.b-form-tags-list');
          let mouseDown = false;
          let startX, scrollLeft;

          let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          let stopDragging = function () {
            mouseDown = false;
          };

          slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) {
              return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
          });

          slider.addEventListener('mousedown', startDragging, false);
          slider.addEventListener('mouseup', stopDragging, false);
          slider.addEventListener('mouseleave', stopDragging, false);

          $('.b-form-tags-input').blur((event) => {
            let input = vm.$refs.formTagFields;
            input.addTag(event.target.value);
          });
        });
      },
      addLevelByChild(arr, level = 1) {
        arr.map((e) => {
          e.level = level;
          if (e.child.length) this.addLevelByChild(e.child, level + 1);
        });
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      addChildWhenUndefined(objArr) {
        objArr.forEach((obj) => {
          if (!obj.hasOwnProperty('child')) {
            obj.child = [];
          }
          this.addChildWhenUndefined(obj.child);
        });
      },
      getAllCheckedItems(list) {
        list.map((e) => {
          if (e.checked) {
            this.allCheckedItems.push(e.id);
          }
          if (e.child) this.getAllCheckedItems(e.child);
        });
      },
      findParentId(array, targetId) {
        for (const obj of array) {
          if (obj.child && obj.child.some((child) => child.id === targetId)) {
            return obj.id;
          }
          const nestedParentId = this.findParentId(obj.child || [], targetId);
          if (nestedParentId) {
            return nestedParentId;
          }
        }
        return null;
      },
      unCheckAll(list) {
        for (const obj of list) {
          obj['checked'] = false;
          if (obj.child && obj.child.length > 0) {
            this.unCheckAll(obj.child);
          }
        }
        this.UPDATE_SELECTED_CHECKS(0);
      },
      subtitlePlural(entity) {
        let name = entity.name || '';
        const currentLanguage = this.$cookies.get('userInfo').language;

        if (currentLanguage === 'en-US') {
          return `${entity.nameEN}${entity.quantity > 1 ? 's' : ''}`;
        }

        if (currentLanguage === 'pt-BR' && entity.quantity > 1) {
          if (name.endsWith('ão')) {
            name = name.replace(/ão$/g, 'ões');
          } else if (name.endsWith('al')) {
            name = name.replace(/al$/g, 'ais');
          }

          if (
            name.endsWith('a') ||
            name.endsWith('e') ||
            name.endsWith('i') ||
            name.endsWith('o') ||
            name.endsWith('u')
          ) {
            name += 's';
          }
        }

        return name;
      },
      async updatedList(list, ids) {
        this.allCheckedItems = [];
        this.getAllCheckedItems(list);
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const departmentId = this.findParentId(list, Number(ids));
        const payload = {
          elements: this.allCheckedItems.length ? this.allCheckedItems : [Number(ids)]
        };

        await this.$http2
          .put(`/api/myconfig/industrial-parameter/movimentation/${departmentId}`, payload)
          .then(() => {
            this.unCheckAll(list);
            this.UPDATE_DRAG_LIST(list);
            this.forceRender = this.forceRender + 1;
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('IntegrationPage.Error'), 'SmileIcon', e.message, 'danger');
            });
            this.UPDATE_DRAG_LIST(JSON.parse(JSON.stringify(this.dragListStart)));
            this.forceRender = this.forceRender + 1;
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      async getDataFields() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        await this.$http2.get('/api/myconfig/industrial-parameter/context').then((response) => {
          this.industrialEntities = response.data.industrialEntities;
          this.appointmentId = this.industrialEntities.find((e) => e.name === 'Apontamento').id;
          this.exhibitionLocation = response.data.exhibitionLocation;
          this.selectedEntity = this.selectedEntity
            ? this.selectedEntity
            : response.data.industrialEntities[0].id;
          this.groupList = response.data.groups
            .map((e) => ({
              value: e.id,
              text: e.name,
              industrialEntityId: e.industrialEntityId
            }))
            .sort((a, b) => a.text.localeCompare(b.text));
          this.industrialStructParameterTypes = response.data.industrialStructParameterTypes.map(
            (e) => ({
              ...e,
              value: e.id,
              text: e.name
            })
          );
        });
        const textFilterQuery = this.filter.textFilter
          .map((filter) => `${filter}`)
          .join('&textFilter=');
        await this.$http2
          .get(
            `/api/myconfig/industrial-parameter/${this.selectedEntity}?textFilter=${textFilterQuery}`
          )
          .then((response) => {
            this.noItemsFound = !!textFilterQuery.length;
            const updatedArr = response.data.data;
            this.addChildWhenUndefined(updatedArr);
            this.addLevelByChild(updatedArr);
            this.UPDATE_DRAG_LIST(updatedArr);
            this.UPDATE_SELECTED_CHECKS(0);
            this.saveActualTree();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      async deleteItems(ids) {
        const payload = ids
          ? ids.map((e) => ({ id: e.id, type: e.type }))
          : [
              {
                type: this.currentType,
                id: this.currentId
              }
            ];
        let toastText = '';

        if (ids) {
          toastText = this.$t('DepartmentPage.DeletedItems');
        } else {
          switch (this.currentType) {
            case 'group':
              toastText = this.$t('DataFieldsPage.GroupDeleted');
              break;
            case 'field':
              toastText = this.$t('DataFieldsPage.FieldDeleted');
              break;
            default:
              break;
          }
        }
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        await this.$http2
          .delete(`/api/myconfig/industrial-parameter`, { data: payload })
          .then(() => {
            this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', toastText, 'success');
            this.forceRender = this.forceRender + 1;
            this.UPDATE_MODAL_DELETE(false);
            this.UPDATE_MODAL_DELETE_SNACK_BAR(false);
            this.UPDATE_SELECTED_CHECKS(0);
            this.getDataFields();
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('Error'), 'FrownIcon', e.message, 'danger');
            });
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      checkedCounter(counter) {
        this.UPDATE_SELECTED_CHECKS(counter);
      },
      getCurrentObject(list) {
        if (!list || list.length === 0) {
          return null;
        }

        for (const item of list) {
          if (item.id === this.currentId) {
            return item;
          }

          if (!item.isMaterials && item.child && item.child.length > 0) {
            const foundItem = this.getCurrentObject(item.child);
            if (foundItem) {
              return foundItem;
            }
          }
        }

        return null;
      },
      getEditInfos(id, _, type) {
        this.currentId = id;
        this.currentType = type;
      },
      changeModalName() {
        this.modalTitle = 'DepartmentPage.New';
        this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(true);
      },
      undoChangesTree() {
        this.UPDATE_DRAG_LIST(JSON.parse(JSON.stringify(this.dragListStart)));
      },
      saveActualTree() {
        this.UPDATE_DRAG_LIST_START(JSON.parse(JSON.stringify(this.dragList)));
      }
    },
    computed: {
      ...mapState('dataFields', {
        dragList: 'dragList',
        dragListStart: 'dragListStart',
        selectedChecks: 'selectedChecks'
      })
    },
    watch: {
      selectedEntity() {
        if (!this.firstContextLoading) this.getDataFields();
        this.firstContextLoading = false;
      }
    }
  };
</script>
<style lang="scss">
  #data-fields-page {
    padding: 16px 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    &:has(.no-items) {
      height: calc(100vh - 136px);
    }

    .block-no-items {
      padding: 16px;
      height: calc(100vh - 300px);

      .no-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #cfc4be;

        .empty-box {
          width: 80px;
          height: 80px;
        }

        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          color: #4c4541;
          margin: 0;
        }

        .subtitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #998f8a;
          padding: 4px 0 12px;
          margin: 0;
        }

        .btn-new {
          border-color: #974900 !important;
          background-color: #974900 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 8px 21px;
          line-height: 20px;
          letter-spacing: 0.175px;

          .add-icon {
            width: 14px;
            height: 14px;
            fill: #fff;
          }
        }

        @media (max-width: 480px) {
          .empty-box {
            width: 40px;
            height: 40px;
          }

          .title {
            font-size: 14px;
          }

          .subtitle {
            font-size: 12px;
            text-align: center;
            padding: 4px 48px 12px;
            line-height: 16px;
          }

          .btn-new {
            padding: 3px 14px;
            color: #fff;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.15px;

            .add-icon {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }

    .header-block {
      padding: 0 16px;
      .title-page {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }

      .entities {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #cfc4be;
        background: #fff;
        overflow-x: scroll;
        white-space: nowrap;

        &.--no-items {
          margin-bottom: 16px;
        }

        .entity {
          cursor: pointer;
          color: #cfc4be;
          display: flex;
          padding: 16px;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;

          &.--current-entity {
            color: #974900;
            box-shadow: 0px -4px 0px 0px #974900 inset;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .search-input {
        display: flex;
        align-items: center;
        padding: 16px 0 8px;

        @media (max-width: 580px) {
          flex-direction: column;
          gap: 8px;

          .div-custom-input {
            max-width: 100%;
            order: 2;
          }
        }

        .div-custom-input {
          display: flex;
          align-items: center;
          border-radius: 4px;
          width: 100%;
          overflow: hidden;
          border: 1px solid #cfc4be;
          .icon-block {
            display: flex;
            align-items: center;
            height: 14px;
            .search-icon {
              width: 14px;
              height: 14px;
              fill: #cfc4be;
            }
          }
          .input-search-fields {
            width: 100% !important;
            border: none;
            overflow: hidden;

            &.focus {
              box-shadow: none;
            }
            &.b-form-tags {
              padding: 0 !important;
              display: flex;
              align-items: center !important;
              .b-form-tags-list {
                width: 100% !important;
                margin-left: 14px !important;
                margin-top: 0;
                height: 100% !important;
                flex-wrap: nowrap !important;
                display: flex !important;
                align-items: center !important;
                overflow: scroll;
                overflow-y: hidden;
                cursor: grabbing;
                cursor: -webkit-grabbing;

                &::-webkit-scrollbar {
                  display: none !important;
                }
                .b-form-tags-field {
                  margin: 9px 0;
                }
              }
            }

            .b-form-tags-button {
              display: none;
            }

            .b-form-tag {
              background-color: #974900;
              margin-right: 0.6rem;
              padding: 2px 8px !important;
              font-size: 13px;
            }
          }
        }

        .add-field {
          display: flex;
          padding-left: 8px !important;
          padding-right: 0 !important;
          @media (max-width: 580px) {
            max-width: 100% !important;
            padding-left: 0 !important;
            flex: 0 0 100% !important;
            width: 100% !important;
          }
          .btn-new {
            border-color: #974900 !important;
            background-color: #974900 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100% !important;
            padding: 8px 0 !important;
            line-height: 20px;
            letter-spacing: 0.175px;

            @media (max-width: 580px) {
              width: 100%;
              display: flex;
              justify-content: center;
              padding: 3.5px 0 !important;
            }

            .add-icon {
              width: 14px;
              height: 14px;
              fill: #fff;
            }
          }

          .icon-option {
            border-color: #974900 !important;
            border-left-color: #fff !important;
            background-color: #974900 !important;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            .dropdown {
              width: 14px;
              height: 14px;
              fill: #fff;
            }
          }
        }
      }
    }
    .body-block {
      &:has(.block-no-results) {
        height: calc(100vh - 316px);
      }
    }
    &:has(.body-block .block-no-results) {
      height: calc(100vh - 135px);
    }
    &:has(.body-block .no-items) {
      height: calc(100vh - 135px);
    }
  }
</style>
