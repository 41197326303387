<template>
  <div class="d-flex">
    <b-sidebar
      id="options-sidebar-right"
      class="sidebar-options-list"
      right
      bg-variant="white"
      :width="device === 'desktop' ? '407px' : device === 'tablet' ? '50vw' : '100vw'"
      v-model="isOpen"
      no-close-on-esc
    >
      <template #header>
        <div
          :class="[
            'header-sidebar d-flex',
            device === 'mobile' ? 'justify-content-start' : 'justify-content-between'
          ]"
        >
          <span
            v-if="device === 'mobile'"
            class="arrow-left-icon"
            @click="closeModal()"
          >
            <ArrowLeft />
          </span>
          <span class="sidebar-title">
            {{
              !isCheckbox ? $t('DataFieldsPage.ListOptions') : $t('DataFieldsPage.SelectionOptions')
            }}
            <span id="info-option">
              <InfoIcon />
            </span>
          </span>
          <Tooltip
            target="info-option"
            :text="
              !isCheckbox
                ? $t('DataFieldsPage.InfoOptionList')
                : $t('DataFieldsPage.SelectionToolTipText')
            "
            placement="top"
          />
        </div>
      </template>
      <template>
        <div>
          <div
            v-for="(item, index) in optionsList"
            :key="item.text + '-' + index"
          >
            <b-col
              md="12"
              class="item-input"
            >
              <b-form-group
                label=""
                :label-for="item.text"
                :invalid-feedback="getInvalidFeedback(item)"
              >
                <b-form-input
                  :id="item.text"
                  :value="item.text"
                  lazy-formatter
                  :formatter="(e) => handleFormatter(e)"
                  @change="(e) => (item.text = e)"
                  :state="verifyState(item)"
                  :placeholder="$t('DataFieldsPage.OptionName')"
                />
              </b-form-group>
              <div
                class="delete-option"
                @click="removeItem(index)"
              >
                <TrashIcon />
              </div>
            </b-col>
          </div>
          <b-button
            class="btn-add-option"
            @click="addNewOption()"
          >
            <AddCircleIcon class="add-icon" />
            {{ $t('DataFieldsPage.NewOption') }}
          </b-button>
        </div>
      </template>

      <template #footer>
        <div class="separator"></div>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div :class="['d-flex', 'buttons']">
            <b-button
              :class="['btn-cancel', 'btn-footer']"
              @click="onCancel"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="onSave()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormGroup, BFormInput, BButton, BCol, BSidebar } from 'bootstrap-vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  import Tooltip from '@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue';

  export default {
    data() {
      return {
        isOpen: false,
        flagEqualOptionName: false,
        isToValidate: false
      };
    },
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BCol,
      BSidebar,
      Tooltip,
      AddCircleIcon: () => import('@core/assets/icons/add-circle.svg'),
      TrashIcon: () => import('@core/assets/icons/trash-icon.svg'),
      InfoIcon: () => import('@core/assets/icons/info-option-icon.svg'),
      ArrowLeft: () => import('@core/assets/icons/arrow-left.svg')
    },
    props: {
      device: {
        type: String,
        default: 'desktop'
      },
      isCheckbox: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.UPDATE_MODAL_OPTIONS_LIST_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('dataFields', [
        'UPDATE_MODAL_OPTIONS_LIST_TOGGLE',
        'UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE',
        'UPDATE_OPTIONS_LIST'
      ]),
      getInvalidFeedback(item) {
        if (!this.isToValidate) return '';

        const isEmpty = item.text.trim() === '';
        if (isEmpty) {
          return this.$t('RequiredField');
        }

        const hasEqualNames =
          this.optionsList.filter((e) => e.text.trim() === item.text.trim()).length > 1;

        return hasEqualNames ? this.$t('DataFieldsPage.EqualOptionName') : '';
      },
      verifyState(item) {
        if (!this.isToValidate) return null;

        const isEmpty = item.text.trim() === '';
        if (isEmpty) {
          return false;
        }

        const hasEqualNames =
          this.optionsList.filter((option) => option.text.trim() === item.text.trim()).length > 1;

        return hasEqualNames ? false : true;
      },
      handleFormatter(e) {
        const trimmedValue = e.trim();
        const hasEqualNames =
          this.optionsList.filter((option) => option.text === trimmedValue).length > 1;
        const hasEmptyOptions = this.optionsList.some((option) => option.text === '');

        if (trimmedValue !== '' && !hasEqualNames && !hasEmptyOptions) {
          this.isToValidate = false;
        }

        return trimmedValue;
      },
      onCancel() {
        this.isToValidate = false;
        this.clearValidations();
        this.clearFields();
        this.UPDATE_OPTIONS_LIST([{ text: '' }, { text: '' }, { text: '' }]);
        this.$emit('onCancel');
      },
      closeModal() {
        this.UPDATE_MODAL_OPTIONS_LIST_TOGGLE(false);
        this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(true);
      },
      removeItem(index) {
        this.optionsList.splice(index, 1);
        this.UPDATE_OPTIONS_LIST(this.optionsList);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      addNewOption() {
        this.optionsList.push({ text: '' });
        this.UPDATE_OPTIONS_LIST(this.optionsList);
      },
      clearFields() {},
      clearValidations() {
        this.isToValidate = false;
        this.flagEqualOptionName = false;
      },
      fieldsValidate() {
        if (this.optionsList.length === 0) this.addNewOption();

        const hasEmptyFields = this.optionsList.some((e) => e.text.trim() === '');

        if (hasEmptyFields) return false;

        const hasDuplicateFields = this.optionsList.some((item, index, arr) => {
          return arr.filter((e) => e.text.trim() === item.text.trim()).length > 1;
        });

        if (hasDuplicateFields) {
          this.flagEqualOptionName = true;
        }

        return !hasDuplicateFields;
      },
      onSave() {
        this.flagEqualOptionName = false;
        this.isToValidate = true;
        const isValid = this.fieldsValidate();
        if (!isValid) return;
        this.isToValidate = false;
        this.$emit('confirm');
      }
    },
    computed: {
      ...mapState('dataFields', {
        isModalOptionsListOpen: 'isModalOptionsListOpen',
        optionsList: 'optionsList'
      })
    },
    watch: {
      isModalOptionsListOpen(v) {
        this.isOpen = v;
        this.clearValidations();
      }
    }
  };
</script>

<style lang="scss">
  .sidebar-options-list {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        width: 100%;
        border-left: 1px solid #cfc4be;
        min-height: 60px;
        padding: 16px;

        .arrow-left-icon {
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-right: 8px;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;

          #info-option {
            margin-left: 8px;
            cursor: pointer;
          }
        }

        .close-icon {
          border: 1px solid #974900;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      box-shadow: none;
      transition: all 0.5s ease-out;
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px;
        border-left: 1px solid #cfc4be;

        .no-options {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          margin: 0 16px;

          .empty-box {
            width: 80px;
            height: 80px;
          }

          .title {
            padding-top: 12px;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            color: #4c4541;
            margin: 0;
          }

          .subtitle {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #998f8a;
            padding: 4px 0 12px;
            margin: 0;
          }

          .btn-new-options {
            border-color: #974900 !important;
            background-color: #974900 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 8px 21px;
            line-height: 20px;
            letter-spacing: 0.175px;

            .add-icon {
              width: 14px;
              height: 14px;
              fill: #fff;
            }
          }

          @media (max-width: 480px) {
            .empty-box {
              width: 40px;
              height: 40px;
            }

            .title {
              font-size: 14px;
            }

            .subtitle {
              font-size: 12px;
              text-align: center;
              padding: 4px 48px 12px;
              line-height: 16px;
            }

            .btn-new-options {
              padding: 3px 14px;
              color: #fff;
              font-size: 12px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.15px;

              .add-icon {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .item-input {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          padding: 0;

          .form-group {
            width: 100%;
          }

          .delete-option {
            display: flex;
            width: 28px;
            height: 28px;
            border-radius: 5px;
            cursor: pointer;
            padding: 8px;
            margin-bottom: 16px;

            &:hover {
              background-color: #ffede2 !important;
            }

            &:active {
              background-color: #ffdbc4 !important;
            }
          }

          &:has(.is-invalid) {
            .delete-option {
              margin-bottom: 30.5px;
            }
          }
        }

        .btn-add-option {
          display: flex;
          gap: 6px;
          font-size: 12px;
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid transparent !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
            box-shadow: none;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
