<template>
  <div
    class="scrollable-arrows__wrapper"
    ref="wrapperElement"
  >
    <div
      class="scrollable-arrows__scrollbar-button-wrapper scrollable-arrows__scrollbar-button-wrapper-left"
      v-if="showLeftArrow"
      @mousedown="handleRequestAnimationLeftButton"
      @mouseup="handleClearRequestAnimationLeftButton"
      @mouseleave="handleClearRequestAnimationLeftButton"
      @touchstart="handleRequestAnimationLeftButton"
      @touchend="handleClearRequestAnimationLeftButton"
      @touchcancel="handleClearRequestAnimationLeftButton"
    >
      <div class="scrollable-arrows__scrollbar-button-container">
        <div class="scrollabe-arrows__blur-area" />
        <div class="scrollable-arrows__scrollbar-button scrollable-arrows__scrollbar-left-button">
          <arrow-left-icon class="scrollable-arrows__left-arrow" />
        </div>
      </div>
    </div>

    <div
      class="scrollable-arrows__master-container"
      ref="masterContainerElement"
    >
      <div
        class="scrollable-arrows__container"
        ref="containerElement"
      >
        <slot />
      </div>
    </div>

    <div
      class="scrollable-arrows__scrollbar-button-wrapper scrollable-arrows__scrollbar-button-wrapper-right"
      v-if="showRightArrow"
      @mousedown="handleRequestAnimationdRightButton"
      @mouseup="handleClearRequestAnimationdRightButton"
      @mouseleave="handleClearRequestAnimationdRightButton"
      @touchstart="handleRequestAnimationdRightButton"
      @touchend="handleClearRequestAnimationdRightButton"
      @touchcancel="handleClearRequestAnimationdRightButton"
    >
      <div class="scrollable-arrows__scrollbar-button-container">
        <div class="scrollabe-arrows__blur-area" />
        <div class="scrollable-arrows__scrollbar-button scrollable-arrows__scrollbar-right-button">
          <arrow-right-icon class="scrollable-right-arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ArrowLeftIcon from '@core/assets/icons/arrow-left.svg';
  import ArrowRightIcon from '@core/assets/icons/arrow-left.svg';

  export default {
    components: { ArrowLeftIcon, ArrowRightIcon },
    updated() {
      const currentSlotContent =
        this.$slots.default && this.$slots.default.map((vnode) => vnode.elm.outerHTML).join('');

      // Compara o conteúdo do slot atual com o anterior
      if (this.previousSlotContent !== currentSlotContent) {
        this.previousSlotContent = currentSlotContent;
        this.handleShowArrows();
      }
    },
    data() {
      return {
        observerInstance: null,
        showLeftArrow: false,
        showRightArrow: false,
        rightRequestAnimation: null,
        leftRequestAnimation: null,
        previousSlotContent: null,
        requestAnimationFrameId: null,
        scrollValue: 5
      };
    },
    beforeMount() {
      window.addEventListener('resize', this.handleShowArrowsListener);
    },
    mounted() {
      this.handleShowArrows();
      this.initHtmlChangeObserver();
      this.$refs.masterContainerElement.addEventListener('wheel', this.preventScroll);

      this.previousSlotContent =
        this.$slots.default && this.$slots.default.map((vnode) => vnode.elm.outerHTML).join('');
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleShowArrowsListener);
      this.$refs.masterContainerElement.removeEventListener('wheel', this.preventScroll);
      this.clearAllRequestAnimations();
      this.observerInstance?.disconnect?.();
    },
    methods: {
      preventScroll(event) {
        event.preventDefault();
      },
      clearAllRequestAnimations() {
        this.handleClearRequestAnimationLeftButton();
        this.handleClearRequestAnimationdRightButton();
      },
      initHtmlChangeObserver() {
        const vm = this;
        const targetNode = document.body;

        const config = {
          childList: true, // Observa adição/remoção de elementos filhos
          attributes: true, // Observa mudanças nos atributos dos elementos
          subtree: true, // Observa também os descendentes do nó alvo
          characterData: true // Observa mudanças no conteúdo de texto dos nós
        };

        // Função de callback que é chamada quando há mudanças no DOM
        const callback = function () {
          vm.handleShowArrowsListener();
        };

        const observer = new MutationObserver(callback);
        this.observerInstance = observer;
        observer.observe(targetNode, config);
      },
      handleScrollInfos() {
        const containerWidth = this.$refs.containerElement?.clientWidth || 0;
        const wrapperWidth = this.$refs.masterContainerElement?.clientWidth || 0;
        const maxScroll = containerWidth - wrapperWidth;

        return {
          containerWidth,
          wrapperWidth,
          maxScroll
        };
      },
      handleShowArrowsListener() {
        setTimeout(() => {
          this.handleShowArrows();
        }, 1000);
      },
      handleShowArrows() {
        const { containerWidth, wrapperWidth, maxScroll } = this.handleScrollInfos();

        this.showLeftArrow = this.$refs.masterContainerElement.scrollLeft > 0;
        this.showRightArrow =
          containerWidth > wrapperWidth &&
          Math.round(this.$refs.masterContainerElement.scrollLeft) < maxScroll;
      },
      handleRequestAnimationdRightButton() {
        this.clearAllRequestAnimations();
        this.requestAnimationFrameId = requestAnimationFrame(this.handleMoveToRight);
      },
      handleRequestAnimationLeftButton() {
        this.clearAllRequestAnimations();
        this.requestAnimationFrameId = requestAnimationFrame(this.handleMoveToLeft);
      },
      handleClearRequestAnimationdRightButton() {
        if (this.requestAnimationFrameId) {
          cancelAnimationFrame(this.requestAnimationFrameId);
          this.requestAnimationFrameId = null;
        }
      },
      handleClearRequestAnimationLeftButton() {
        if (this.requestAnimationFrameId) {
          cancelAnimationFrame(this.requestAnimationFrameId);
          this.requestAnimationFrameId = null;
        }
      },
      isScrollLeftNegative() {
        return this.$refs.masterContainerElement.scrollLeft - this.scrollValue < 0;
      },
      isScrollLeftExcessive() {
        const { maxScroll } = this.handleScrollInfos();

        return this.$refs.masterContainerElement.scrollLeft + this.scrollValue > maxScroll;
      },
      handleMoveToRight() {
        this.clearAllRequestAnimations();
        const { maxScroll } = this.handleScrollInfos();

        if (!this.showLeftArrow) {
          this.showLeftArrow = true;
        }

        if (Math.floor(this.$refs.masterContainerElement.scrollLeft) === maxScroll) {
          this.$refs.masterContainerElement.scrollLeft = maxScroll;
          this.clearAllRequestAnimations();
          this.showRightArrow = false;
          return;
        }

        if (this.isScrollLeftExcessive()) {
          this.showRightArrow = false;
          this.$refs.masterContainerElement.scrollLeft = maxScroll;
          this.clearAllRequestAnimations();
          return;
        }

        this.$refs.masterContainerElement.scrollLeft += this.scrollValue;
        this.requestAnimationFrameId = requestAnimationFrame(this.handleMoveToRight);
      },
      handleMoveToLeft() {
        this.clearAllRequestAnimations();
        this.showLeftArrow = true;

        if (
          Math.floor(this.$refs.masterContainerElement.scrollLeft) === 0 ||
          this.isScrollLeftNegative()
        ) {
          this.clearAllRequestAnimations();
          this.showLeftArrow = false;
          this.$refs.masterContainerElement.scrollLeft = 0;
          return;
        }

        this.showRightArrow = true;
        this.$refs.masterContainerElement.scrollLeft -= this.scrollValue;
        this.requestAnimationFrameId = requestAnimationFrame(this.handleMoveToLeft);
      }
    }
  };
</script>

<style scoped lang="scss">
  .scrollable-arrows__wrapper {
    display: flex;
    position: relative;
    width: 100%;

    .scrollable-arrows__scrollbar-button-wrapper {
      display: flex;
      height: 40px;
      width: 42px;

      .scrollable-arrows__scrollbar-button-container {
        position: relative;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &:hover,
        &:active {
          .scrollable-arrows__scrollbar-button {
            border-color: #974900;

            svg {
              fill: #974900;
            }
          }
        }

        &:hover {
          .scrollable-arrows__scrollbar-button {
            background: #ffede2;
          }
        }

        &:active {
          .scrollable-arrows__scrollbar-button {
            background: #ffdbc4;
          }
        }

        .scrollable-arrows__scrollbar-button {
          position: absolute;
          display: flex;
          padding: 3px;
          border-radius: 500px;
          height: fit-content;
          min-height: fit-content;
          border: 1px solid #cfc4be;
          background: #fff;
          cursor: pointer;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);

          svg {
            height: 12px;
            width: 12px;
            fill: #4c4541;
          }

          &.scrollable-arrows__scrollbar-left-button {
            left: 16px;
          }

          &.scrollable-arrows__scrollbar-right-button {
            right: 16px;

            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      &,
      .scrollabe-arrows__blur-area {
        height: 40px;
        width: 42px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .scrollabe-arrows__blur-area {
        background: #fff;
        filter: blur(5px);
      }

      &.scrollable-arrows__scrollbar-button-wrapper-left {
        left: -8px;
      }

      &.scrollable-arrows__scrollbar-button-wrapper-right {
        right: -8px;
      }
    }

    .scrollable-arrows__master-container {
      display: flex;
      overflow-x: auto;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      .scrollable-arrows__container {
        display: flex;
        flex-wrap: nowrap;
        flex: 1;
      }
    }
  }
</style>
